<template>
  <div>
    <!-- 沉浸式头部 -->
    <nav-bar header-title="千万庆生礼 福利派发中" backColor="#fff" :show-back="true" :header-back="newAppBack" />
    <div class="box">
      <img class="c-title" :src="baseImgUrl + 'c-title.png'" alt="">
      <div class="content cs" v-if="getStatus == 1">
        <p>全民健身季优惠券领取成功！</p>
        <p>已领取{{ cid == 3 ? '福建' : '广州' }}专属优惠券，<br />
          可在主会场购买{{ cid == 3 ? '福建' : '广州' }}1200元以上礼品卡</p>
      </div>
      <div class="content cf" v-if="getStatus == 2">
        <p>全民健身季优惠券领取失败！</p>
        <p>领券二维码已过期失效，<br />
          下次记得及时扫码领取哦~</p>
      </div>
      <div @click="jump" class="button f32 fw6 row-center-center">前往全民健身季主会场</div>
    </div>

  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, appOpenWeb } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import headerMixin from '@/mixin/headerMixin'
export default {
  components: {
    navBar,
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      getStatus: 0,
      baseImgUrl: 'https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/',
      cid: ''
    }
  },
  async created() {
    await this.$getAllInfo()
    this.init()
  },
  methods: {
    newAppBack,
    async init() {
      const q = this.$route.query
      this.cid = q.city
      this.$axios.post(this.baseURLApp + '/anniversary/sixth/preheat/sendAllVoucher', {
        userId: this.userId,
        token: this.token,
        cityId: q.city
      }).then(res => {
        this.getStatus = 1
      }).catch(err => {
        this.getStatus = 2
      })
    },
    jump() {
      appOpenWeb('', `${window.location.origin}/#/superdeer-activity/88buy-2024/index`)
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  background: #FF5D47;
  height: 100vh;
  padding-bottom: 70px;

  .c-title {
    width: 100%;
  }

  .content {
    width: 610px;
    height: 565px;
    margin: -100px auto 0px;
    z-index: 10;
    position: relative;
    text-align: center;
    font-size: 24px;
    color: #1A1A1A;
    font-weight: 400;

    &.cs {
      background: url("https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/c-s.png") top/100% auto no-repeat;
    }

    &.cf {
      background: url("https://img.chaolu.com.cn/ACT/88buy-preheat-2024/making/c-f.png") top/100% auto no-repeat;
    }

    >p:first-child {
      font-family: PuHuiTi;
      font-size: 40px;
      margin-bottom: 26px;
      padding-top: 360px;
    }
  }



}

.button {
  width: 440px;
  height: 96px;
  background: linear-gradient(180deg, #FFB400 0%, #FF470E 100%);
  box-shadow: 0px 6px 6px 0px rgba(255, 49, 21, 0.8);
  border-radius: 48px;
  border: 4px solid #FFE597;
  color: #FFFFFF;
  margin: 60px auto 0px;
}
</style>
